/* ======================
Font Variation 
========================*/


@for $i from 1 through length($fontList) {
  .template-font-#{$i} {
    .heading,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
      font-family: nth($fontList, $i);
    }

    .heading {
      &.font-reenie {
        font-family: 'Reenie Beanie', cursive;
      }
    }

    // Minicart
    & .mini-cart {
      .shopping-cart {
        .shop-inner {
          ul {
            &.product-list {
              li {
                .content {
                  .inner {
                    h4 {
                      font-family: nth($fontList, $i);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }


    .mega__width--fullscreen {
      .mega__list {
        li {
          a {
            span {
              font-family: nth($fontList, $i);
            }
          }
        }
      }
    }

    // Mainmenu
    .mainmenu-wrapper {
      .page_nav {
        ul {
          &.mainmenu {
            li {
              &.label-1 {
                > a {
                  span {
                    font-family: nth($fontList, $i);
                  }
                }
              }
            }
          }
        }
      }
    }

    // Footer Font
    .footer-widget {
      .footer-menu {
        ul {
          &.ft-menu-list {
            li {
              a {
                font-family: nth($fontList, $i);
              }
            }
          }
        }
      }
    }


    // Copyright
    .copyright {
      ul {
        &.bk-copyright-menu {
          li {
            a {
              font-family: nth($fontList, $i);
            }
          }
        }
      }
    }

    .copyright {
      .copyright-right {
        p {
          font-family: nth($fontList, $i);
        }
      }
    }

    .footer-widget {
      .footer-address {
        p {
          font-family: nth($fontList, $i);
        }
      }
    }

    .form-style--1 {
      .input-box {
        input,
        textarea {
          font-family: nth($fontList, $i);
        }
      }
    }

    .testimonial_style--1 {
      .content {
        p {
          font-family: nth($fontList, $i);
        }

        .testimonial-info {
          .client-info {
            span {
              font-family: nth($fontList, $i);
            }
          }
        }
      }
    }

    .service {
      &.service--1 {
        .content {
          p {
            font-family: nth($fontList, $i);
          }
        }
      }
    }

    .service {
      &.service--2 {
        .content {
          h4 {
            font-family: nth($fontList, $i);
          }

          p {
            font-family: nth($fontList, $i);
          }
        }
      }
    }
  }
}