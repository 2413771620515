.home-carousel {
  max-width: 50%;
  min-width: 1200px;
  /* height: 700px; */
  background-color: #f3efe5;
  margin: auto;
  margin-top: -130px;
  position: relative;
}

.carousel-container {
  margin-top: 50px;
  width: 100%;
  height: 90%;
  padding: 2em 0;
}
