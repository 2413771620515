/*============================
 * Button
================================*/


a,
button {
  &.bk-btn {
    padding: 0 36px;
    height: 55px;
    line-height: 55px;
    border-width: 0;
    border-radius: 6px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .07);
    color: #fff;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0em;
    font-family: $bodyFont;

    &.theme-btn {
      background-color: #0038E3;
    }

    &:hover {
      -webkit-transform: translateY(-3px);
      -ms-transform: translateY(-3px);
      transform: translateY(-3px);
    }
  }
}


.button-group {
  .spacing {
    height: 46px;
  }
}

a,
button {
  &.brook-btn {
    color: #fff;
    border-width: 0;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .07);
    font-weight: 600;
    letter-spacing: 0em;
    font-family: $bodyFont;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    border-style: solid;
    cursor: pointer;
    -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
    transition: all .3s cubic-bezier(.645, .045, .355, 1);

    &.text-dark {
      color: #001029;
    }

    &.text-theme {
      color: $theme-color;
    }

    &.text-white {
      color: #ffffff;
    }

    &.space-between {
      margin: 5px;
    }

    &.with-no-shadow {
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    &.bk-btn-theme {
      background: $theme-color;
    }

    &.bk-btn-theme-2 {
      background: $theme-color-2;
    }

    &.bk-btn-yellow {
      background: $yellow-color;
    }

    &.red-color-2 {
      background: $red-color-2;
    }

    &.bk-btn-secondary {
      background: #899664;
    }

    &.bk-btn-white {
      background: #ffffff;
      color: #222222;
    }

    &.bk-btn-pink {
      background: #f55d4e;
    }

    &.bk-btn-red {
      background: $red-color;
    }

    &.red-color-5 {
      background: $red-color-5;
    }

    &.color-green {
      background: #899664;
    }

    &.color-green-2 {
      background: #88db5e;
    }

    &.brown-3 {
      background: $brown-3;
    }

    &.red-color-3 {
      background: $red-color-3;

      &:hover {
        background: $red-color-3;
        color: #fff;
      }
    }

    &.bk-btn-dark {
      background: $heading-color;

      &:hover {
        background: $theme-color;
        color: #ffffff;
      }

      &.red-color-3 {
        &:hover {
          background: $red-color-3 !important;
          color: #fff;
        }
      }
    }


    &.bk-btn-gradient-pink {
      color: #fff;
      border-color: transparent;
      background: -webkit-gradient(linear, left top, left bottom, color-stop(40%, #FF3F85), color-stop(85%, #FAA720));
      background: -moz-linear-gradient(150deg, #FF3F85 40%, #FAA720 85%);
      background: -webkit-linear-gradient(150deg, #FF3F85 40%, #FAA720 85%);
      background: -o-linear-gradient(150deg, #FF3F85 40%, #FAA720 85%);
      background: -ms-linear-gradient(150deg, #FF3F85 40%, #FAA720 85%);
      background: linear-gradient(150deg, #FF3F85 40%, #FAA720 85%);
    }

    &.bk-btn-theme-border {
      border-width: 1px;
      background: transparent;
      border-color: $theme-color;
      color: $theme-color;

      &:hover {
        background: $theme-color;
        border-color: $theme-color;
        color: #ffffff;
      }

    }


    &.bk-btn-secondary-border {
      border-width: 1px;
      background: #ffffff;
      border-color: #899664;
      color: #899664;

      &:hover {
        background: #899664;
        border-color: #899664;
        color: #ffffff;
      }

    }

    &.bk-btn-white-border {
      border-width: 1px;
      background: transparent;
      border-color: #ddd;
      color: #222222;

      &:hover {
        background: #ffffff;
        border-color: #ffffff;
        color: #222222;
      }

    }

    &.bk-btn-pink-border {
      border-width: 1px;
      background: #ffffff;
      border-color: #f55d4e;
      color: #f55d4e;

      &:hover {
        background: #f55d4e;
        border-color: #f55d4e;
        color: #ffffff;
      }

    }

    &.btn-rounded {
      border-radius: 6px;
    }

    &.btn-rectangle {
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      border-radius: 0;
    }

    &.btn-bullet {
      -moz-border-radius: 500px;
      -webkit-border-radius: 500px;
      border-radius: 500px;
    }

    &.btn-xs-size {
      padding: 0 20px;
      height: 40px;
      line-height: 40px;
      font-size: 13px;
    }

    &.btn-sm-size {
      padding: 0 30px;
      height: 48px;
      line-height: 48px;
      font-size: 14px;
    }

    &.btn-sd-size {
      padding: 0 36px;
      height: 55px;
      line-height: 55px;
      font-size: 14px
    }

    &.btn-lg-size {
      padding: 0 40px;
      height: 62px;
      line-height: 62px;
      font-size: 16px;
    }

    &:hover {
      -webkit-transform: translateY(-3px);
      -ms-transform: translateY(-3px);
      transform: translateY(-3px);
    }
  }
}


a {
  &.more-details-btn {
    font-weight: 600;
    font-size: 14px;
    color: #222;
    -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
    transition: all .3s cubic-bezier(.645, .045, .355, 1);

    &.font-16 {
      font-size: 16px;
    }

    &:hover {
      color: $theme-color;
    }

    i {
      padding-left: 20px !important;
      color: $theme-color;
      -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
      transition: all .3s cubic-bezier(.645, .045, .355, 1);
    }


    &.red-color {
      i {
        color: $red-color;
      }

      &:hover {
        color: $red-color;
      }
    }

    &.yellow-color-2 {
      i {
        color: $yellow-color-2;
      }

      &:hover {
        color: $yellow-color-2;
      }
    }


  }

}


.view-more-btn {
  &.heading-color {
    a {
      span {
        color: $heading-color;
      }
    }
  }

  &.headingfont {
    a {
      span {
        font-family: $heading-font;
      }
    }
  }

  &.font-18 {
    a {
      font-size: 18px;
    }
  }

  &.font-700 {
    a {
      font-weight: 700;
    }
  }

  &.font-600 {
    a {
      font-weight: 600;
    }
  }

  a {
    font-size: 14px;

    span {
      color: #ffffff;
      -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
      transition: all .3s cubic-bezier(.645, .045, .355, 1);

      &.btn-arrow {
        transition: all .5s !important;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        width: 73px;
        height: 2px;
        background: currentColor;
        margin-left: 14px;
        color: #FCB72B;
        font-size: 14px;
        font-family: $bodyFont;

        &::before {
          content: "";
          position: absolute;
          right: 1px;
          width: 15px;
          height: 15px;
          border: 2px solid currentColor;
          border-left: 0;
          border-bottom: 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
          top: -6px;
        }
      }
    }

    &:hover {
      span {
        &.btn-arrow {
          -webkit-transform: translateX(100%);
          -ms-transform: translateX(100%);
          transform: translateX(100%);
        }
      }
    }
  }

  &.brown-color {
    a {
      span {
        &.btn-arrow {
          background: $brown;
          color: $brown;
        }
      }
    }
  }
}


/* ========================
    Slider Video Play 
=========================*/


.yu2fvl {
  z-index: 9999;
  top: 0;
}

.yu2fvl-iframe {
  display: block;
  height: 100%;
  width: 100%;
  border: 0;
}

.yu2fvl-overlay {
  z-index: 9998;
  background: #000;
  opacity: 0.8;
}

.yu2fvl-close {
  position: absolute;
  top: 1px;
  left: 100%;
  display: inline-block;
  height: 35px;
  width: 35px;
  line-height: 35px;
  text-align: center;
  background: #f10 !important;
  background-color: #f10 !important;
  color: #ffffff;
  border: 0;
  background: none;
  cursor: pointer;
}


@media #{$md-layout} {
  .yu2fvl-close {
    left: auto;
    right: 0;
    top: auto;
    bottom: 100%;
  }
}

@media #{$sm-layout} {
  .yu2fvl-close {
    left: auto;
    right: 0;
    top: auto;
    bottom: 100%;
  }

  .video {
    h2 {
      color: #ffffff;
      font-size: 32px;
    }

    h4 {
      font-size: 22px;
      line-height: 32px;
    }
  }
}