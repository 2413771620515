/* variables */
:root {
  --gold: #ebc03f;
  --dark-gold: #b89535;
  --cream: #f3efe5;
  --dark-cream: #f3efe5;
  --black: #1d1f22;
  --black-lighter: #1d1f2260;

  --h-navy: #170055;
  --h-blue: #3e00ff;
  --h-light-blue: #03e9f4;
  --h-purple: #ae00fb;
  --h-green: #b5ffd9;

  --h-white: #f9f7f2;
  --h-black-text: #1d1f22;
  --h-heading-font-size: 3.5em;
  --h-text-font-size: 1.1em;
  --h-heading-letter-spacing: 3px;
  --h-text-letter-spacing: 1px;
  --h-heading-font-weight: 500;
  --h-text-font-weight: 300;
  --h-heading-text-padding: 0.5em 0.5em 0 0.5em;
  --icon-size: 80px;
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--gold) var(--black);
}

::-webkit-scrollbar {
  height: 12px;
  width: 8px;
  background: #000;
}

::-webkit-scrollbar-thumb {
  background: #fffa;
  -webkit-border-radius: 4px;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

/* wow move-up - apply these classes for move-up animation */

body {
  background: var(--cream);
  color: var(--black);
}
.background-video {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  padding: none;
  position: absolute;
  object-fit: cover;
}
@media screen and (max-width: 600px) {
  .background-video {
    object-fit: contain;
  }
}
.txt-right {
  text-align: right;
}
.anim-wrapper {
  position: relative;
}
.elem-canvas {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
}

.neon-btn {
  position: relative;
  display: inline-block;
  padding: 25px 30px;
  margin: 40px 0;
  color: #03e9f4;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.5s;
  letter-spacing: 4px;
  overflow: hidden;
  margin-right: 50px;
}
.neon-btn:hover {
  background: #03e9f4;
  color: #050801;
  box-shadow: 0 0 5px #03e9f4, 0 0 15px #03e9f4, 0 0 25px #03e9f4,
    0 0 50px #03e9f4;
  -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
}
.neon-btn {
  /* filter: hue-rotate(270deg); */
}
.neon-btn span {
  position: absolute;
  display: block;
}
.neon-btn span:nth-child(1) {
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #03e9f4);
  animation: animate1 1s linear infinite;
}
@keyframes animate1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}

.neon-btn span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #03e9f4);
  animation: animate2 1s linear infinite;
  animation-delay: 0.25s;
}
@keyframes animate2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}
.neon-btn span:nth-child(3) {
  bottom: 0;
  right: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #03e9f4);
  animation: animate3 1s linear infinite;
  animation-delay: 0.5s;
}
@keyframes animate3 {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}

.neon-btn span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #03e9f4);
  animation: animate4 1s linear infinite;
  animation-delay: 0.75s;
}
@keyframes animate4 {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}

.logo img {
  height: 100px !important;
  object-fit: contain;
}

/* About section */
.about-body {
  background-color: var(--h-white) !important;
  background-image: url(./assets/img/about/light_lines.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.about-logo {
  height: 140px !important;
}
.about-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: justify;
}
.about-section-team {
  display: flex;
  margin-bottom: 2em;
}
@media screen and (max-width: 992px) {
  .about-section-team {
    flex-direction: column;
  }
  .about-team-r {
    flex-direction: column-reverse;
  }
}
.center-wrap {
  max-width: 40em;
  text-align: center;
}
.fade:not(.show) {
  opacity: 100 !important;
}
.wrapped-text {
  margin: 1.3em 1em;
  margin-top: 0;
  opacity: 100 !important;
}
.sector-text {
  transform: translateY(200px);
  z-index: 9999;
}
.sector-heading {
  line-height: 120%;
  letter-spacing: 0.12em;
  font-size: 50px;
  font-weight: 400;
  letter-spacing: 0.09em;
  line-height: 143%;
  font-family: Brown, Bukra, Arial, sans-serif;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 28p;
}
/* About testimonials */
.bg_color--pt {
  background-color: var(--h-white);
  background-image: url(./assets/img/about/light_lines.svg);
}
.pt--padding {
  padding: 1em 0em;
}

.about-team {
  max-height: 20em;
}
.about-team--left--l {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 2em 0em;
  min-width: 20em;
  padding-right: 1.5em;
}
.about-team--left--r {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 2em 0em;
  min-width: 20em;
}

@media screen and (max-width: 475px) {
  .about-team--left--l {
    min-width: 0;
  }
  .about-team--left--r {
    min-width: 0;
  }
}
.about--heading {
  font-size: 1.8em;
  padding-left: 0;
  padding-right: 0;
}
.a-mini-text {
  padding-bottom: 0;
  margin-bottom: 0;
}
.about--t-justified {
  text-align: justify;
  text-justify: inter-word;
  margin: 1.5em 0;
}
.a-mini-text-hover {
  color: var(--gold);
}

/* Home slider */
.heading-slider--h {
  color: var(--h-black-text) !important;
}
.a-text--main {
  font-size: var(--font-size-text);
  color: var(--dark-gold) !important;
}
.content-slider {
  padding: 3em;
}

.footer-invest-container {
  pointer-events: all;
  width: 100%;
  background-color: #ffff;
  padding: 2em 1em;
}

.footer-logo {
  margin-right: 30px;
}
.invest-content {
  /* margin: 65px 20px 55px 20px; */
  /* width: 100%; */
  /* display: inline-flex; */
}
.footer-invest {
  width: 33%;
}
.footer-invest-title {
  letter-spacing: 0;
  line-height: 135%;
  color: #565759;
  margin: 0 0 10px;
  text-transform: none;
  white-space: nowrap;
  /* margin-left: 9%; */
  margin-top: 20px;
}
.invest-label {
  /* margin-left: 9%; */
  color: #b89535;
  margin-top: -0.0225em;
  text-transform: uppercase;
}
.invest-label:hover {
  color: #ffd02a;
}
.invest-icon {
  margin-left: 10px;
  color: #b89535;
  margin-top: -0.0225em;
  text-transform: uppercase;
}

.invest-icon:hover {
  color: #ffd02a;
}
.invest-description {
  font-size: 14px;
  /* margin-left: 9%; */
  line-height: 150%;
  color: #565759;
}

.footer-subscribe-container {
  pointer-events: all;
  width: 100%;
  background-color: #f9f7f2;
}

.invest-subscribe-content {
  margin: 35px auto;
  width: 100%;
  display: inline-flex;
}
.footer-subscribe-title {
  font-weight: var(---h-heading-font-weight);
  line-height: 148%;
  letter-spacing: 0;
  color: #565759;
  font-size: 21px;
  /* margin-left: 9%; */
  font-family: Brown, Bukra, Arial, sans-serif;
}
.subcribe-input {
  height: 60px;
  width: 100%;
  display: block;
  border: 1.5px solid #e8e9e9;
  border-radius: 30px;
  padding: 0 30px;
  font-size: 16px;
  font-weight: 400;
  color: #999 !important;
  background-color: #fff;
  text-transform: none !important;
  font-family: Brown, Zarid, Arial, sans-serif !important;
}
.submitContainer {
  position: absolute;
  right: 5%;
  width: 108px;
  top: 50%;
  transform: translate(0, -50%);
}
.submit-button {
  line-height: 120%;
  letter-spacing: 0.12em;
  font-size: 12px;
  height: 48px;
  width: 100%;
  font-weight: 400;
  letter-spacing: 0.09em;
  line-height: 120%;
  font-family: Brown, Bukra, Arial, sans-serif;
  background-color: #1d1f22;
  border: 0;
  border-radius: 24px;
  color: #fff;
  cursor: pointer;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  overflow: visible;
  position: relative;
}
.footer-subscribe-invest {
  /* width: 33%; */
  position: relative;
  visibility: inherit;
  align-self: start;
  padding: 0.5em 1em;
}

.footer-align-desktop {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-social-invest {
  /* display: inline-flex; */
  /* margin-left: 5%; */
  /* width: 33%; */
  position: relative;
  visibility: inherit;
  padding: 0.5em 1em;
}
.footer-social-group {
  list-style: none;
}
.footer-social-title {
  line-height: 148%;
  letter-spacing: 0;
  color: #565759;
  font-size: 16px;
  font-family: Brown, Bukra, Arial, sans-serif;
  margin-top: 20px;
}

.triangle-area {
  position: relative;
  z-index: 1;
  width: 100%;
}
.fix-bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}
.triangle-area--right {
  position: relative;
  height: 400px;
  width: 100%;
}
.tri-point {
  position: absolute;
}
.tri-point:first-child {
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}
.tri-point:nth-child(2) {
  left: 0;
  bottom: 0;
}
.tri-point:last-child {
  right: 0;
  bottom: 0;
}
.full-container {
  min-height: 80vh;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.triangle-lines {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.triangle-lines img {
  width: 100%;
  height: 100%;
}
/* React slider */
.d-flex {
  display: flex !important;
}
.flex-row {
  flex-direction: row;
}
.flex-column {
  flex-direction: column;
}
.justify-content-start {
  justify-content: flex-start;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-around {
  justify-content: space-around;
}
.justify-content-even {
  justify-content: space-evenly;
}
.justify-content-center {
  justify-content: center;
}

.align-items-start {
  align-items: flex-start;
}
.align-items-end {
  align-items: flex-end;
}
.align-items-center {
  align-items: center;
}
.align-items-baseline {
  align-items: baseline;
}
.align-items-stretch {
  align-items: stretch;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-start {
  align-self: flex-start;
}

.common-grey-color {
  color: rgb(190, 190, 190);
}

.m-auto {
  margin: auto;
}
.mb-40 {
  margin-bottom: 40px;
}

.flex-gap-0 {
  gap: 0px;
}

.flex-gap-20 {
  gap: 20px;
}

.flex-gap-40 {
  gap: 40px;
}

.flex-gap-80 {
  gap: 80px;
}

/* Social about */

.social-icon li a:hover {
  background-color: var(--gold) !important;
}

.page-footer {
  background: #000 !important;
  border-top: 4px solid var(--gold);
}

.hover-bg-change {
  background-color: var(--black);
}

.triangle-area {
  position: relative;
  z-index: 2;
  padding: 2rem 0;
}
.fix-bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}
.fix-img-container:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  z-index: 1;
  background: rgba(20, 20, 20, 0.4);
}

.triangle-area--right {
  position: relative;
  height: 400px;
  width: 100%;
}
.tri-point {
  position: absolute;
}
.tri-point:first-child {
  left: 43%;
  top: 0;
  transform: translateX(-43%);
}
.tri-point:nth-child(2) {
  left: -10px;
  bottom: -10px;
}
.tri-point:last-child {
  right: -10px;
  bottom: -10px;
}

.full-container {
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.triangle-lines {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.tri-line-12 {
  position: absolute;
  width: 80%;
  border-top: 4px solid var(--gold);
  top: 50%;
  left: 32%;
  transform: rotate(54deg);
}
.tri-line::before {
  position: absolute;
  content: "";
  background-color: rgb(241, 111, 133);
  filter: blur(50px);
  /* opacity: 0.5; */
  height: 20px;
  width: 60px;
}
.hovered-line::before {
  width: 100%;
  background-color: var(--gold);
  transition: all 1s linear;
  filter: blur(25px);
  height: 10px;
}
.tri-line-23 {
  position: absolute;
  width: 90%;
  border-top: 4px solid var(--gold);
  top: 92%;
  left: 5%;
}
.tri-line-31 {
  position: absolute;
  width: 80%;
  border-top: 4px solid var(--gold);
  top: 50%;
  left: -12%;
  transform: rotate(-54deg);
}
.triangle-lines img {
  width: 100%;
  height: 100%;
}

@media only screen and (min-width: 990px) and (max-width: 1200px) {
  .tri-point:first-child {
    left: 41%;
    top: 4%;
  }
}
@media only screen and (min-width: 767px) and (max-width: 990px) {
  .tri-point:first-child {
    left: 44%;
    top: -12%;
  }
  .tri-point:nth-child(2) {
    left: 0%;
    bottom: -14%;
  }
  .tri-point:last-child {
    right: 0%;
    bottom: -14%;
  }
  .tri-line-23 {
    top: 105%;
  }
}
@media only screen and (min-width: 573px) and (max-width: 767px) {
  .tri-point:first-child {
    left: 43%;
    top: 0%;
  }
  .tri-point:nth-child(2) {
    left: -1%;
    bottom: -1%;
  }
  .tri-point:last-child {
    right: -1%;
    bottom: -1%;
  }
  .tri-line-23 {
    top: 92%;
  }
}
@media only screen and (min-width: 478px) and (max-width: 573px) {
  .tri-point:first-child {
    left: 41%;
    top: 4%;
  }
  .tri-point:nth-child(2) {
    left: -4%;
    bottom: 8%;
  }
  .tri-point:last-child {
    right: -4%;
    bottom: 8%;
  }
  .tri-line-23 {
    top: 82%;
  }
}
@media only screen and (max-width: 478px) {
  .tri-point:first-child {
    left: 36%;
    top: 18%;
    /* transform: translateX(-43%); */
  }
  .tri-point:nth-child(2) {
    left: 2%;
    bottom: 15%;
  }
  .tri-point:last-child {
    right: 2%;
    bottom: 15%;
  }
  .tri-line-23 {
    top: 75%;
  }
}

@keyframes waves {
  0% {
    transform: translate(-50%, -50%) scale(50%);
    opacity: 0.8;
  }
  100% {
    transform: translate(-50%, -50%) scale(100%);
    opacity: 0;
  }
}
@keyframes waves2 {
  0% {
    transform: translate(-50%, -50%) scale(50%);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(100%);
    opacity: 0;
  }
}

.bk-gradation .item-grid .dot-wrap .dot:hover::before {
  animation: waves 1s infinite linear;
}
.bk-gradation .item-grid .dot-wrap .dot:hover::after {
  animation: waves2 1s infinite linear;
}

.fix-tri-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(1.1);
  transition: cubic-bezier(0.19, 1, 0.22, 1);
}

@keyframes hoverChange {
  from {
    transform: scale(1.1);
  }
  to {
    transform: scale(1);
  }
}
@keyframes leaveChange {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}

.set-imgs--sectors {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bg--lines-white {
  background-color: var(--h-white);
  background-image: url(./assets/img/about/light_lines.svg);
}

.bg-big--heading {
  background-color: var(--h-white);
  background-image: url(./assets/img/about/light_lines.svg);
  padding: 4em 0;
}

.indigenous-project {
  display: flex;
  align-items: center;
}

.indigenous-image-project {
  width: 25%;
  height: fit-content;
  margin-right: 15px;
}
.indigenous-font-project.indigenous-font-project {
  font-size: 3rem;
}

@media only screen and (max-width: 475px) {
  .indigenous-project {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .indigenous-image-project {
    width: 60%;
    height: fit-content;
  }
  .indigenous-font-project.indigenous-font-project {
    font-size: 2rem;
    text-align: center;
  }
}
.bg-big--heading--t {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 3rem;
  line-height: 3rem;
  word-break: break-word;
}
.golden--text {
  color: var(--dark-gold);
}

.bg-big--heading--t-container {
  display: flex;
  flex-direction: column;
  margin: 2em 0em;
}

.bg-big--heading--t--l {
  padding-right: 1.5em;
  text-align: justify;
  text-justify: inter-word;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 100;
}
.bg-big--heading--t--l-full {
  padding-right: 1.5em;
  text-align: justify;
  text-justify: inter-word;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 100;
}
@media screen and (min-width: 1000px) {
  .bg-big--heading--t {
    font-size: 4rem;
  }
  .bg-big--heading--t--l {
    width: 50%;
  }
  .bg-big--heading--t-container {
    flex-direction: row;
  }
  .bg-big--heading--t--l-full {
    width: 100%;
  }
}

.card--container {
  display: flex;
  justify-content: space-between;
  min-height: 25em;
  padding: 3em;
  background: url(./assets/img/projects/bg.jpg) no-repeat center center fixed;
  /* opacity: 1; */
}

@media only screen and (max-width: 1000px) {
  .card--container {
    flex-direction: column;
  }
}

.cards--box-container {
  width: 25%;
  padding: 1em 1em;
  border: 1px solid #424242;
  margin: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 10em;
}
.card--box-container-text {
  text-align: justify;
  color: var(--h-white);
}
.card--box-container-heading {
  color: var(--h-white);
  text-align: center;
}
.cards--box-container-icon {
  margin: 1em;
}
.contact-us-forn-hel {
  background-color: #f9f7f2;
  color: black;
}
.contact-us-label {
  line-height: 120%;
  letter-spacing: 0.12em;
  font-weight: 400;
  text-transform: uppercase;
  display: block;
  margin-bottom: 8px;
}
.contact-us-heading {
  line-height: 135%;
  letter-spacing: 0;
  font-size: 37px;
  color: #1d1f22;
  font-weight: 100;
  text-transform: none;
  margin-bottom: 36px;
  width: 100%;
}
.contact-us-inputtype {
  display: block;
  width: 100%;
  height: 60px;
  border: 1.5px solid #e8e9e9;
  border-radius: 4px;
  padding: 0 10px;
  background-color: #ffffff;
  text-transform: none !important;
  color: #1d1f22;
}
.contact-us-button {
  font-weight: 400;
  letter-spacing: 0.09em;
  line-height: 120%;
  width: 137px;
  height: 40px;
  margin: 33px 0 0;
  background-color: var(--gold);
  color: var(--h-white);
  text-align: center;
  text-transform: uppercase;
  border-radius: 30px;
  -webkit-transition: background-color 0.2s ease-in-out;
  -o-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s;
}
.contact-us-button:hover {
  background-color: var(--dark-gold);
}

.more-info--container {
  background-color: var(--h-white);
  padding: 5rem 0;
  background-image: url(./assets/img/about/light_lines.svg);
}
.more-info--top {
  display: flex;
}
@media only screen and (max-width: 475px) {
  .more-info--top {
    flex-direction: column;
  }
}
@media only screen and (max-width: 1000px) {
  .cards--box-container {
    width: 100%;
    margin: 1em 0;
  }
}
.more-info--top-right {
  padding-left: 2rem;
}
@media only screen and (max-width: 475px) {
  .partnerships {
    letter-spacing: 6px;
  }
}

.more-info--top-left-icon {
  width: 50rem;
}
.less-info--container {
  background-color: var(--h-white);
  padding: 2rem 0;
}
/* .less-info--top-right {
  padding-top: 2rem;
} */
.less-info--top-left-icon {
  width: 100%;
}
.a-image__img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: relative;
}

.link:hover {
  color: var(--gold);
}
