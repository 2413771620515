.videoContainer {
  margin: 120px 0px 0px;
  text-align: center;
  background-image: url(../../assets/img/about/light_lines.svg);
}

.leadershipHeader {
  padding-top: 20px;
  font-weight: 1000;
  font-size: 40px;
  color: #222222;
  font-size: 3rem;
  padding-bottom: 15px;
  text-decoration: underline;
  margin-bottom: unset;
  text-align: center;
}

.futureVisionVideos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 10px;
}

.videos {
  width: 700px;
  height: 460px;
  background-color: #b89535;
  border: 3px solid #ffda02;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.innerVideo {
  flex: 1;
  width: 100%;
  height: 80%;
  background-color: #ccc;
  position: relative;
}

.abousUs {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0px;
  overflow: hidden;
}

.videoDescText {
  font-size: 20px;
  color: white;
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 970px) {
  .videos {
    width: 100%;
  }
}
