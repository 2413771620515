@charset "UTF-8";

@font-face {
    font-family: "linea-basic-10";
    src: url("../fonts/linea-basic-10.eot");
    src: url("../fonts/linea-basic-10.eot?#iefix") format("embedded-opentype"),
        url("../fonts/linea-basic-10.woff") format("woff"),
        url("../fonts/linea-basic-10.ttf") format("truetype"),
        url("../fonts/linea-basic-10.svg#linea-basic-10") format("svg");
    font-weight: normal;
    font-style: normal;

}

[data-icon]:before {
    font-family: "linea-basic-10" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
    font-family: "linea-basic-10" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-basic-accelerator:before {
    content: "a";
}

.icon-basic-alarm:before {
    content: "b";
}

.icon-basic-anchor:before {
    content: "c";
}

.icon-basic-anticlockwise:before {
    content: "d";
}

.icon-basic-archive:before {
    content: "e";
}

.icon-basic-archive-full:before {
    content: "f";
}

.icon-basic-ban:before {
    content: "g";
}

.icon-basic-battery-charge:before {
    content: "h";
}

.icon-basic-battery-empty:before {
    content: "i";
}

.icon-basic-battery-full:before {
    content: "j";
}

.icon-basic-battery-half:before {
    content: "k";
}

.icon-basic-bolt:before {
    content: "l";
}

.icon-basic-book:before {
    content: "m";
}

.icon-basic-book-pen:before {
    content: "n";
}

.icon-basic-book-pencil:before {
    content: "o";
}

.icon-basic-bookmark:before {
    content: "p";
}

.icon-basic-calculator:before {
    content: "q";
}

.icon-basic-calendar:before {
    content: "r";
}

.icon-basic-cards-diamonds:before {
    content: "s";
}

.icon-basic-cards-hearts:before {
    content: "t";
}

.icon-basic-case:before {
    content: "u";
}

.icon-basic-chronometer:before {
    content: "v";
}

.icon-basic-clessidre:before {
    content: "w";
}

.icon-basic-clock:before {
    content: "x";
}

.icon-basic-clockwise:before {
    content: "y";
}

.icon-basic-cloud:before {
    content: "z";
}

.icon-basic-clubs:before {
    content: "A";
}

.icon-basic-compass:before {
    content: "B";
}

.icon-basic-cup:before {
    content: "C";
}

.icon-basic-diamonds:before {
    content: "D";
}

.icon-basic-display:before {
    content: "E";
}

.icon-basic-download:before {
    content: "F";
}

.icon-basic-exclamation:before {
    content: "G";
}

.icon-basic-eye:before {
    content: "H";
}

.icon-basic-eye-closed:before {
    content: "I";
}

.icon-basic-female:before {
    content: "J";
}

.icon-basic-flag1:before {
    content: "K";
}

.icon-basic-flag2:before {
    content: "L";
}

.icon-basic-floppydisk:before {
    content: "M";
}

.icon-basic-folder:before {
    content: "N";
}

.icon-basic-folder-multiple:before {
    content: "O";
}

.icon-basic-gear:before {
    content: "P";
}

.icon-basic-geolocalize-01:before {
    content: "Q";
}

.icon-basic-geolocalize-05:before {
    content: "R";
}

.icon-basic-globe:before {
    content: "S";
}

.icon-basic-gunsight:before {
    content: "T";
}

.icon-basic-hammer:before {
    content: "U";
}

.icon-basic-headset:before {
    content: "V";
}

.icon-basic-heart:before {
    content: "W";
}

.icon-basic-heart-broken:before {
    content: "X";
}

.icon-basic-helm:before {
    content: "Y";
}

.icon-basic-home:before {
    content: "Z";
}

.icon-basic-info:before {
    content: "0";
}

.icon-basic-ipod:before {
    content: "1";
}

.icon-basic-joypad:before {
    content: "2";
}

.icon-basic-key:before {
    content: "3";
}

.icon-basic-keyboard:before {
    content: "4";
}

.icon-basic-laptop:before {
    content: "5";
}

.icon-basic-life-buoy:before {
    content: "6";
}

.icon-basic-lightbulb:before {
    content: "7";
}

.icon-basic-link:before {
    content: "8";
}

.icon-basic-lock:before {
    content: "9";
}

.icon-basic-lock-open:before {
    content: "!";
}

.icon-basic-magic-mouse:before {
    content: "\"";
}

.icon-basic-magnifier:before {
    content: "#";
}

.icon-basic-magnifier-minus:before {
    content: "$";
}

.icon-basic-magnifier-plus:before {
    content: "%";
}

.icon-basic-mail:before {
    content: "&";
}

.icon-basic-mail-multiple:before {
    content: "'";
}

.icon-basic-mail-open:before {
    content: "(";
}

.icon-basic-mail-open-text:before {
    content: ")";
}

.icon-basic-male:before {
    content: "*";
}

.icon-basic-map:before {
    content: "+";
}

.icon-basic-message:before {
    content: ",";
}

.icon-basic-message-multiple:before {
    content: "-";
}

.icon-basic-message-txt:before {
    content: ".";
}

.icon-basic-mixer2:before {
    content: "/";
}

.icon-basic-mouse:before {
    content: ":";
}

.icon-basic-notebook:before {
    content: ";";
}

.icon-basic-notebook-pen:before {
    content: "<";
}

.icon-basic-notebook-pencil:before {
    content: "=";
}

.icon-basic-paperplane:before {
    content: ">";
}

.icon-basic-pencil-ruler:before {
    content: "?";
}

.icon-basic-pencil-ruler-pen:before {
    content: "@";
}

.icon-basic-photo:before {
    content: "[";
}

.icon-basic-picture:before {
    content: "]";
}

.icon-basic-picture-multiple:before {
    content: "^";
}

.icon-basic-pin1:before {
    content: "_";
}

.icon-basic-pin2:before {
    content: "`";
}

.icon-basic-postcard:before {
    content: "{";
}

.icon-basic-postcard-multiple:before {
    content: "|";
}

.icon-basic-printer:before {
    content: "}";
}

.icon-basic-question:before {
    content: "~";
}

.icon-basic-rss:before {
    content: "\\";
}

.icon-basic-server:before {
    content: "\e000";
}

.icon-basic-server2:before {
    content: "\e001";
}

.icon-basic-server-cloud:before {
    content: "\e002";
}

.icon-basic-server-download:before {
    content: "\e003";
}

.icon-basic-server-upload:before {
    content: "\e004";
}

.icon-basic-settings:before {
    content: "\e005";
}

.icon-basic-share:before {
    content: "\e006";
}

.icon-basic-sheet:before {
    content: "\e007";
}

.icon-basic-sheet-multiple:before {
    content: "\e008";
}

.icon-basic-sheet-pen:before {
    content: "\e009";
}

.icon-basic-sheet-pencil:before {
    content: "\e00a";
}

.icon-basic-sheet-txt:before {
    content: "\e00b";
}

.icon-basic-signs:before {
    content: "\e00c";
}

.icon-basic-smartphone:before {
    content: "\e00d";
}

.icon-basic-spades:before {
    content: "\e00e";
}

.icon-basic-spread:before {
    content: "\e00f";
}

.icon-basic-spread-bookmark:before {
    content: "\e010";
}

.icon-basic-spread-text:before {
    content: "\e011";
}

.icon-basic-spread-text-bookmark:before {
    content: "\e012";
}

.icon-basic-star:before {
    content: "\e013";
}

.icon-basic-tablet:before {
    content: "\e014";
}

.icon-basic-target:before {
    content: "\e015";
}

.icon-basic-todo:before {
    content: "\e016";
}

.icon-basic-todo-pen:before {
    content: "\e017";
}

.icon-basic-todo-pencil:before {
    content: "\e018";
}

.icon-basic-todo-txt:before {
    content: "\e019";
}

.icon-basic-todolist-pen:before {
    content: "\e01a";
}

.icon-basic-todolist-pencil:before {
    content: "\e01b";
}

.icon-basic-trashcan:before {
    content: "\e01c";
}

.icon-basic-trashcan-full:before {
    content: "\e01d";
}

.icon-basic-trashcan-refresh:before {
    content: "\e01e";
}

.icon-basic-trashcan-remove:before {
    content: "\e01f";
}

.icon-basic-upload:before {
    content: "\e020";
}

.icon-basic-usb:before {
    content: "\e021";
}

.icon-basic-video:before {
    content: "\e022";
}

.icon-basic-watch:before {
    content: "\e023";
}

.icon-basic-webpage:before {
    content: "\e024";
}

.icon-basic-webpage-img-txt:before {
    content: "\e025";
}

.icon-basic-webpage-multiple:before {
    content: "\e026";
}

.icon-basic-webpage-txt:before {
    content: "\e027";
}

.icon-basic-world:before {
    content: "\e028";
}