/* =======================
    Custom Carousel Area 
===========================*/

/*-- Hero Item --*/
.hero-item {
  width: 100%;
  height: 750px;
  align-items: center;
  display: flex !important;
  justify-content: center;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 1;
  // Responsive
  @media #{$laptop-device}{
    height: 650px;
  }
  @media #{$lg-layout}{
    height: 500px;
  }
  @media #{$md-layout}{
    height: 450px;
  }
  @media #{$sm-layout}{
    height: 350px;
  }
}

/*-- Hero Content Two For Hero Slider --*/
.slider-font-image {
  position: relative;
  bottom: -30px;
}

.hero-content-2 {
  &.left {
    text-align: left;
  }

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }

  // All Element Selector
  & > * {
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: fadeOutUp;
  }

  & h6 {
    font-size: 18px;
    line-height: 33px;
    font-weight: 700;
    text-transform: capitalize;
    color: $white;
    margin-bottom: 20px;

    // Responsive
    @media #{$laptop-device}{
      font-size: 15px;
      line-height: 28px;
    }
    @media #{$lg-layout}{
      font-size: 17px;
      line-height: 32px;
    }
    @media #{$md-layout}{
      font-size: 16px;
      line-height: 33px
    }
    @media #{$sm-layout}{
      font-size: 12px;
      line-height: 24px;
    }
  }

  & h3 {
    font-size: 80px;
    line-height: 90px;
    font-weight: 400;
    color: $white;
    margin-bottom: 30px;

    // Responsive
    @media #{$laptop-device}{
      font-size: 68px;
      line-height: 76px;
    }
    @media #{$lg-layout}{
      font-size: 58px;
      line-height: 65px;
    }
    @media #{$md-layout}{
      font-size: 44px;
      line-height: 49px;
    }
    @media #{$sm-layout}{
      font-size: 33px;
      line-height: 37px;
    }
  }

  & p {

  }

  & .btn {
  }
}

/*-- Slide Content In Animation --*/
.slick-active {

  /*-- Hero Content Two For Hero Slider --*/
  & .hero-content-2 {

    // All Element Selector
    & > * {
      animation-name: fadeInUp;

      // Child Selector
      &:nth-child(1) {
        animation-delay: 0.5s;
      }

      &:nth-child(2) {
        animation-delay: 1s;
      }

      &:nth-child(3) {
        animation-delay: 1.5s;
      }

      &:nth-child(4) {
        animation-delay: 2s;
      }

      &:nth-child(5) {
        animation-delay: 2.5s;
      }

      &:nth-child(6) {
        animation-delay: 3s;
      }

    }
  }

}

.carousel-slider {
  .thumb {
    img {
      width: 100%;
    }
  }

  .content {
    padding: 0 30px 0 0;

    .spacing {
      height: 28px;
    }

    h5 {
      font-size: 18px;
      margin-bottom: 14px;
      font-weight: 600;
      font-family: $bodyFont;
      line-height: 1.23;
    }

    p {
      line-height: 1.58;
      font-size: 14px;
      font-weight: 500;
      font-family: $bodyFont;
      color: $bodyColor;
      margin: 0;
    }
  }
}










