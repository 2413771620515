/* =====================
Info Boxes 
=======================*/


.bk-info-boxes{
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat( 4,1fr);

    @media #{$lg-layout} {
        grid-template-columns: repeat(2, 1fr);
    }

    @media #{$md-layout} {
        grid-template-columns: repeat(2, 1fr);
    }

    @media #{$sm-layout} {
        grid-template-columns: repeat(1, 1fr);
    }

    


    .info-grid-box{
        &.has-image{
            background-position: 50%;
            background-size: cover;
            background-repeat: no-repeat;
        }
        
        .box-content{
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: stretch;
            -webkit-align-items: stretch;
            -ms-flex-align: stretch;
            align-items: stretch;

            .box-content-inner{
                padding: 50px 55px;

                @media #{$sm-layout} {
                    padding: 30px 18px;
                }
                
                .box-info{

                }
            }
        }

        &.space-variation{
            .box-content{
                .box-content-inner{
                    padding-top: 100px;
                    padding-right: 60px;
                    padding-bottom: 100px;
                    padding-left: 60px;
                }
            }
        }
    }

}

















