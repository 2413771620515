/**************************************************************
	
	STYLESHEET INDEXING
	|
	|
	|___ Default Styles
	|	|___ variables
	|	|___ mixins
	|	|___ reset
	|	|___ typography
	|	|___ animations
	|	|___ shortcode
	|
	|
	|___Elements Styles
	|	|___ Button
	|	|___ section-title
	|	|___ service
	|	|___ Counterup
	|	|___ team
	|	|___ wavify
	|	|___ wavify
	|	|___ blog-list
	|	|___ contact
	|	|___ about-us
	|	|___ call-to-action
	|	|___ slick-arrow
	|	|___ motivational-archive
	|	|___ social
	|	|___ shortnote
	|	|___ flexible-image-slide
	|	|___ carousel-slider
	|	|___ countdown
	|	|___ progress-charts
	|	|___ gradation
	|	|___ typed-text
	|	|___ pricing-plans
	|	|___ career
	|	|___ maintenance
	|	|___ player
	|	|___ 404
	|	
	|
	|
	|___Components Styles
	|	|___ video 
	|	|___ breadcrumb
	|	|___ instagram
	|	|___ popup
	|	|___ shopping-cart
	|	|___ mega-menu
	|	|___ toolbar
	|	|___ typography
	|	|___ list
	|	|___ pricing-box
	|	|___ icon-boxes
	|	|___ dividers
	|	|___ message-box
	|	|___ accordion
	|	|___ googlemap
	|	|___ rows-column
	|	|___ info-boxes
	|	|___ loader
	|	|___ demo-option
	|
	|
	|
	|___Portfolio Styles
	|	|___ portfolio-grid 
	|	|___ portfolio-grid-metro 
	|	|___ portfolio-caption
	|	|___ masonry-button
	|	|___ parallax
	|	|___ has-center
	|	|___ type-hover
	|	|___ portfolio-details
	|
	|
	|
	|___Blog Grid Styles
	|	|___ blod-grid 
	|	|___ pagination 
	|	|___ blog-mesonry
	|	|___ blog-grid-no-flex
	|	|___ blog-grid-minimal
	|	|___ blog-details
	|	|___ blog-sidebar
	|
	|
	|
	|___Shop Sidebar Styles
	|	|___ shop-sidebar 
	|	|___ single-product 
	|	|___ cart
	|	|___ checkout
	|	|___ compare
	|	|___ my-account
	|	|___ vertical-slide
	|	|___ login
	|
	|
	|
	|
	|___Template Styles
	|	|___ header
	|	|___ sidebar-header
	|	|___ navigation
	|	|___ banner
	|	|___ feature
	|	|___ startup-service
	|	|___ freelancer
	|	|___ hamburger-menu
	|	|___ left-vertical-header
	|	|___ fullscreen-slider
	|	|___ sidebar-mobilemenu
	|	|___ header-broadsheets
	|	|___ onepage
	|	|___ foodie
	|	|___ portfolio-slide
	|	|___ photo-slide
	|	|___ vertical-slide
	|	|___ musician
	|	|___ vertical-slider-portfolio
	|	|___ shop-slide
	|	|___ product
	|	|___ revolution
	|	|___ presentation
	|	|___ carousel-slider
	|	 
	|	
	|	|___ Color Variation
	|	|
	|	|___ color-2
	|	|___ font-variation
	|	|___ preview
	|	|___ responsive
	|
	|___ END STYLESHEET INDEXING

***************************************************************/

@import url("https://fonts.googleapis.com/css2?family=Hind:wght@300&display=swap");

/*===================================
    Default Styles
====================================*/
@import "default/variables";
@import "default/mixins";
@import "default/reset";
@import "default/typography";
@import "default/animations";
@import "default/shortcode";

/*====================================
	Elements Styles
=====================================*/
@import "elements/button";
@import "elements/section-title";
@import "elements/service";
@import "elements/counterup";
@import "elements/team";
@import "elements/wavify";
@import "elements/wavify";
@import "elements/brand";
@import "elements/blog-list";
@import "elements/contact";
@import "elements/about";
@import "elements/call-to-action";
@import "elements/slick-arrow";
@import "elements/motivational-archive";
@import "elements/social";
@import "elements/shortnote";
@import "elements/flexible-image-slide";
@import "elements/carousel-slider";
@import "elements/countdown";
@import "elements/progress-charts";
@import "elements/gallery";
@import "elements/gradation";
@import "elements/typed-text";
@import "elements/pricing-plans";
@import "elements/career";
@import "elements/maintenance";
@import "elements/testimonial";
@import "elements/404";
@import "elements/tabs";
@import "elements/player";

/*================================
	Components Style
=================================*/
@import "components/video";
@import "components/breadcrumb";
@import "components/instagram";
@import "components/popup";
@import "components/shopping-cart";
@import "components/mega-menu";
@import "components/toolbar";
@import "components/typography";
@import "components/list";
@import "components/pricing-box";
@import "components/icon-boxes";
@import "components/dividers";
@import "components/message-box";
@import "components/accordion";
//@import 'components/googlemap';
@import "components/rows-column";
@import "components/info-boxes";
//@import 'components/loader';
@import "components/hero-banner";
@import "components/slider";
//@import 'components/demo-option';

/*==================================
	Portfolio Styles
===================================*/
@import "portfolio/portfolio-grid";
@import "portfolio/portfolio-grid-metro";
@import "portfolio/portfolio-caption";
@import "portfolio/masonry-button";
@import "portfolio/paralax";
@import "portfolio/has-center";
@import "portfolio/type-hover";
@import "portfolio/portfolio-details";

/*=====================================
	Blog Grid Styles
======================================*/
@import "blog-grid/blod-grid";
@import "blog-grid/pagination";
@import "blog-grid/blog-mesonry";
@import "blog-grid/blog-grid-no-flex";
@import "blog-grid/blog-grid-minimal";
@import "blog-grid/blog-details";
@import "blog-grid/blog-sidebar";

/*=================================
	Template Styles
==================================*/
@import "template/header";
@import "template/sidebar-header";
@import "template/navigation";
@import "template/startup-service";
@import "template/freelancer";
@import "template/hamber-menu";
@import "template/left-vertical-header";
@import "template/fullscreen-slider";
@import "template/sidebar-mobilemenu";
@import "template/header-broadsheets";
@import "template/onepage";
@import "template/foodie";
@import "template/portfolio-slide";
@import "template/photo-slide";
@import "template/vertical-slide";
@import "template/musician";
@import "template/vertical-slider-portfolio";
@import "template/shop-slide";
@import "template/product";
@import "template/revulution";
@import "template/presentation";
@import "template/footer";

/*================================
	Color Variation
=================================*/
@import "color/color-2";
@import "color/font-variation";
@import "color/preview";
@import "color/responsive";

// COLORS
.black {
  color: $black !important;
}
.white {
  color: $white !important;
}
.dark {
  color: $dark !important;
}
.light-dark {
  color: $light-dark !important;
}
.cream {
  color: $cream !important;
}
.dark-cream {
  color: $dark-cream !important;
}
.gold {
  color: $gold !important;
}
.dark-gold {
  color: $dark-gold !important;
}

// BG-COLORS
.bg-black {
  background: $black !important;
}
.bg-white {
  background: $white !important;
}
.bg-dark {
  background: $dark !important;
}
.bg-light-dark {
  background: $light-dark !important;
}
.bg-cream {
  background: $cream !important;
}
.bg-dark-cream {
  background: $dark-cream !important;
}
.bg-gold {
  background: $gold !important;
}
.bg-dark-gold {
  background: $dark-gold !important;
}

// h2 {
//   font-size: calc(28px + 22 * (100vw - 480px) / 960);
//   line-height: 1.21;
//   letter-spacing: 3px;
//   color: $black;
//   margin-bottom: 47px;
//   font-family: $font-hind;
//   text-transform: uppercase;
//   // max-width: 470px;
// }
// p {
//   font-size: 21px;
//   line-height: 2em;
//   color: $black;
//   margin-bottom: 45px;
//   word-wrap: break-word;
//   max-width: 100%;
//   // font-family: $font-brown;
//   font-family: $font-hind;
//   // max-width: 700px;
// }

.h-theme {
  font-size: calc(38px + 22 * (100vw - 480px) / 960);
  line-height: 1.21;
  letter-spacing: 8px;
  // margin-bottom: 47px;
  font-family: $font-hind;
  text-transform: uppercase;
  font-weight: 100;
}

.h-theme-md {
  font-size: calc(28px + 22 * (100vw - 480px) / 960);
  line-height: 1.21;
  letter-spacing: 8px;
  // margin-bottom: 47px;
  font-family: $font-hind;
  text-transform: uppercase;
  font-weight: 100;
}
.h-theme-sm {
  font-size: 2rem;
  line-height: 1.1;
  letter-spacing: 3px;
  font-family: $font-hind;
  text-transform: uppercase;
  font-weight: 100;
}
.h-theme-xs {
  font-size: 1.4rem;
  line-height: 1;
  letter-spacing: 2px;
  font-family: $font-hind;
  text-transform: uppercase;
  font-weight: 100;
}
.p-theme {
  font-size: 21px;
  font-family: $font-hind;
  line-height: 1.5em;
  max-width: 100%;
  //   letter-spacing: $h-heading-letter-spacing;
  font-weight: $h-heading-font-weight;
  word-wrap: break-word;
  // margin-bottom: 45px;
  // padding: $h-heading-text-padding;
}

.dark-lines-bg:before {
  top: 0;
  left: 0;
  // background: url($light-lines);
  background-size: cover;
  background-position: center;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.1;
}

// .sector-block {
//   display: flex;
//   height: 100px;
//   align-items: center;
// }
.sector-block {
  --icon-fill: $dark-gold;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: $cream;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  position: relative;
  min-height: 120px;
  cursor: pointer;
}

.sector-block .sector-icon {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  position: relative;
  -webkit-transition: fill, 0.3s ease;
  -o-transition: fill, 0.3s ease;
  transition: fill, 0.3s ease;
  z-index: 1;
}
.sector-block .sector-icon i {
  color: $gold;
}
.sector-block:hover .sector-icon i {
  color: $white;
}

.sector-icon {
  height: var(--icon-size, 24px);
  width: var(--icon-size, 24px);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  // fill: $cream;
}
.sector-link {
  font-weight: 400;
  letter-spacing: 0.09em;
  line-height: 120%;
  font-family: $font-1, Arial, sans-serif;
  margin: 0 0 0 15px;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  font-size: 12px;
}
.sector-img {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
.sector-img img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: relative;
  opacity: 0.2;
  transition: all 0.5s ease-out;
}
.sector-block:hover .sector-img img {
  transform: scale(1.1);
  opacity: 0.4;
}

.with--drop:hover .header-transparent {
  background: #333;
}
.mega__list {
  display: grid;
  grid-gap: $grid-gap;
  padding: 120px 0;
}
@media (min-width: 1240px) {
  .mega__list {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .mega__list {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
